<nav class="navbar  navbar-expand-lg py-1 navbar-dark ps-3" style="background-color:#00274C;">
  <div class="col-sm-2 col-md-3 col-xs-4 col-lg-4 ">
    <img src="../../assets/images/EnvisionHealthcare_LOGO_REV-01.png" class=" img-responsive"/>
  </div>
  <div class="col-sm-8  col-md-6 mt-4 col-xs-4 col-lg-5  pl-0 ps-sm-0 me-5">
      <span  class="text-white"><h4>Centralized Quality Forms</h4></span>
  </div>
  <div class="col-sm-2  col-md-3 col-lg-3 col-xs-4 mt-4  me-1">
      <span class="text-white" >{{sessionStorage.getItem('loggedInUser')}}</span>
      <!-- <i *ngIf="sessionStorage.getItem('loggedInUser')" class="fa fa-sign-out fa-bg-white ps-1" style="color:white;cursor: hand;" (click)="LogoutAndCloseTab()"></i> -->
  </div> 
</nav>


