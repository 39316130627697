// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  caseDetailsByIDInfoAPIUrl: 'https://dev-integrations.evhc.net/api/v1/caretool/caseinfo/',
  caseDetailsByIDAPIUrl: 'https://dev-integrations.evhc.net/api/v1/caretool/cases/',
  saveCaseDetailsAPIUrl: 'https://dev-integrations.evhc.net/api/v1/caretool/cases/',
  providerInfoAPIUrl: 'https://dev-integrations.evhc.net/api/v1/caretool/providers',
  formValuesAPIUrl:'https://dev-integrations.evhc.net/api/v1/caretool/cases/formvalues',
  clientIDValue:'',
  clientSecretValue:'',
  caserecPatientDemographicAPIUrl:'https://dev-integrations.evhc.net/api/v1/caretool/caserec/cases/',
  fileuploadUrl: 'https://dev-integrations.evhc.net/api/v1/caretool/cases/',
  // logServiceUrl: 'https://dev-integrations.evhc.net/api/v1/caretool/log?logType=Logstash',
  logServiceUrl: 'https://p49qo5vem4.execute-api.us-east-1.amazonaws.com/dev/weblog',
  envName : "dev",
  informationLogging: true,
  logIndexName: "quality-cqf-logs-dev",
  cutoffdate: "05/01/2024",
  cutoffdatePQRAnes4: "01/01/2025"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
